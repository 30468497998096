import React, { useState } from 'react';
import Navbar from './Navbar';
import FAQ from './FAQ';
import Simulator from './Simulator';
import { useNavigate } from 'react-router-dom';
import PCimg from './icons/pc.png';
import { AiOutlineCaretRight } from "react-icons/ai";
import PricingPlans from './PricingPlans';
import Demo from './Demo';


const Home = () => {
  const [isMultiSitePlan, setIsMultiSitePlan] = useState(false);
  const navigate = useNavigate();

  const handlePlanSelection = (priceId) => {
      navigate(`/payment?priceId=${priceId}`);
  };

  const togglePlan = () => {
      setIsMultiSitePlan(!isMultiSitePlan);
  };

  const btnLogin = () => {
    navigate('/login');
  };

  return (
<div className="bg-white min-h-screen text-black">
  <Navbar />
  <div className="container mx-auto p-6 text-center mb-20">
  <h1 className="text-5xl sm:text-6xl md:text-7xl lg:text-8xl xl:text-9xl font-bold mx-auto w-1/2 mt-20 mb-5">
    Creative Popups
  </h1>
  <p className="mt-4 text-lg">
    Boost your website's engagement with tailored popups. Customize, deploy, and analyze with ease using our modern platform:
  </p>
  <button className="mt-6 px-6 py-3 bg-blue-600 text-white rounded-full" onClick={btnLogin}>
    Join for free
  </button>
</div>




  <div className="text-center py-12 mt-20">
  <h2 className="text-3xl font-bold">Give an upgrade to your website with PopSpark.</h2>
  <h3 className="mt-2 text-xl font-light">Easy, clean and innovative.</h3>
  <div className="mt-8 mx-10 grid grid-cols-1 md:grid-cols-3 gap-8">
    <div className="bg-yellow-400 p-6 rounded-lg shadow-lg">
      <h3 className="text-2xl font-black text-black">Advanced Analytics</h3>
      <p className="mt-2 text-black font-bold">Gain detailed insights into your audience's behavior and preferences. Monitor performance metrics in real-time for immediate feedback. Generate custom reports to understand the effectiveness of your marketing campaigns.</p>
    </div>
    <div className="bg-yellow-400 p-6 rounded-lg shadow-lg">
      <h3 className="text-2xl font-black text-black">User-Friendly Interface</h3>
      <p className="mt-2 text-black font-bold">Navigate through the platform with ease, thanks to a clean and simple interface. Get started in minutes with our easy setup process. Create stunning content with our drag-and-drop editor, no coding required.</p>
    </div>
    <div className="bg-yellow-400 p-6 rounded-lg shadow-lg">
      <h3 className="text-2xl font-black text-black">Customizable Popups</h3>
      <p className="mt-2 text-black font-bold">Design popups that fit your brand perfectly with our extensive customization options. Use tailored popups to capture leads and drive more conversions. Create interactive and engaging popups that enhance storytelling and build a connection with your audience.</p>
    </div>
  </div>
</div>


<Demo className="pt-12 container mx-auto text-center"/>



<div className="text-center py-12">
  <h2 className="text-3xl font-bold">Want a tool that is user-friendly?</h2>
  <p className="mt-2 text-xl font-light">That's PopSpark!</p>
  <div className="mt-8 space-y-8 flex flex-col items-center">
    
    <div className="w-full md:w-1/2 flex flex-col items-center">
      <div className="bg-white p-6 rounded-lg shadow-lg flex-1 w-full">
        <h3 className="text-2xl font-bold">Step 1</h3>
        <p className="mt-2">Create an account. In PopSpark, creating your account is easy!</p>
      </div>
    </div>

    <div className="w-full md:w-1/2 flex flex-col items-center">
      <div className="bg-white p-6 rounded-lg shadow-lg flex-1 w-full">
        <h3 className="text-2xl font-bold">Step 2</h3>
        <p className="mt-2">Start creating your popups! You can use your dashboard to select or add a domain, choose a popup (or create a new one!) and customise it to match your needs!</p>
      </div>
    </div>

    <div className="w-full md:w-1/2 flex flex-col items-center">
      <div className="bg-white p-6 rounded-lg shadow-lg flex-1 w-full">
        <h3 className="text-2xl font-bold">Step 3</h3>
        <p className="mt-2">Export your popups! Don't forget to copy and paste the JavaScript code into your <code>&lt;head&gt;</code> tag and let your popups come to life on your website!</p>
      </div>
    </div>

  </div>
</div>


  <PricingPlans id="pricing"/>


  <FAQ className="my-20" id="FAQ" />

  <div className=" text-black text-center py-12">
    <h2 className="text-5xl font-bold">Start now.</h2>
    <p className="mt-2 text-xl">There's a free trial waiting for you!</p>
    <button className="mt-6 px-6 py-3 bg-blue-600 text-white rounded-full" onClick={btnLogin}>Join for free</button>
  </div>
</div>

  );
}

export default Home;
