import React, { useState, useEffect, useRef } from 'react';
import {jwtDecode} from 'jwt-decode';

const getUserIdFromToken = () => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
        try {
            const decoded = jwtDecode(token);
            console.log("This is the decoded token:", decoded.userId);
            return decoded.userId;
        } catch (error) {
            console.error('Error decoding token:', error);
            return null;
        }
    }
    return null;
};

const AddWeb = ({ callback }) => {
    const [userData, setUserData] = useState("");
    const [domain, setDomain] = useState('');
    const [websiteId, setWebsiteId] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [selectedDomain, setSelectedDomain] = useState(null);
    const [pops, setPops] = useState([]);
    const fileInputRefs = useRef({});
    const [popList, setPopList] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [popTitle, setPopTitle] = useState('');
    const [popDescription, setPopDescription] = useState('');
    const [popNotificationTime, setPopNotificationTime] = useState('');
    const [popImage, setPopImage] = useState('');

    useEffect(() => {
        const userId = getUserIdFromToken();
        const fetchUserData = async () => {
            try {
                const response = await fetch(`/api/user/${userId}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                    }
                });
                const data = await response.json();
                if (response.ok) {
                    setUserData(data);
                } else {
                    throw new Error('Failed to fetch user data');
                }
            } catch (error) {
                setError('Error fetching data: ' + error.message);
            }
        };
        fetchUserData();
    }, []);

    useEffect(() => {
        if (websiteId) {
            const fetchPopups = async () => {
                try {
                    const response = await fetch(`/api/websites/${websiteId}/popups`);
                    const data = await response.json();
                    if(response.ok){
                        setPopList(data);
                    } else {
                        throw new Error(data.message || 'Failed to fetch Popups info');
                    }
                } catch (error) {
                    setError('Error fetching popups: ' + error.message);
                }
            };
            fetchPopups();
        }
    }, [websiteId]);

    const handleAddDomain = async (e) => {
        e.preventDefault();
        if (!userData) {
            setError("User data is not loaded yet");
            return;
        }

        if (userData.user.planId === 'Single Spark Plan' && userData.user.websites.length >= 1) {
            setMessage(`You have reached the limit of your plan. Please <a href="/payment?priceId=price_1P6iu5L2M4UlckgMemvRMpBV" className="text-blue-500 hover:text-blue-700">upgrade</a> to add more websites.`);
            return;
        }

        try {
            const response = await fetch(`/api/user/websites`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userId: userData.user._id, domain })
            });
            const result = await response.json();
            if (response.ok) {
                setMessage("Domain added successfully!");
                setUserData({ ...userData, user: { ...userData.user, websites: [...userData.user.websites, domain] } });
            } else {
                throw new Error(result.message || "Failed to add domain");
            }
        } catch (error) {
            setError('Error adding domain: ' + error.message);
        }
    };

    const deleteDomain = async (domain) => {
        try {
            const response = await fetch(`/api/user/websites`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userId: userData.user._id, domain })
            });
            const result = await response.json();
            if (response.ok) {
                setMessage("Domain removed successfully!");
                setUserData({
                    ...userData,
                    user: {
                        ...userData.user,
                        websites: userData.user.websites.filter((web) => web !== domain)
                    }
                });
            } else {
                throw new Error(result.message || "Failed to delete domain");
            }
        } catch (error) {
            setError('Error deleting domain: ' + error.message);
        }
    };

    const handleSelectDomain = (domain) => {
        setSelectedDomain(domain);
        callback(domain);
        const fetchWebsiteId = async () => {
            try {
                const response = await fetch(`/api/websites/id?domain=${encodeURIComponent(domain)}`);
                const data = await response.json();
                if (response.ok) {
                    setWebsiteId(data.websiteId);
                    console.log("This is WebsiteId:", data.websiteId);
                } else {
                    throw new Error(data.message || 'Failed to fetch website ID');
                }
            } catch (error) {
                console.error('Error fetching website ID:', error);
            }
            return (domain, websiteId)
        };
        fetchWebsiteId();
    };
    const fetchPopups = async () => {
        try {
            const response = await fetch(`/api/websites/${websiteId}/popups`);
            const data = await response.json();
            if(response.ok){
                console.log("Info dos popups: ", data);
                setPopList(data);
            } else {
                throw new Error(data.message || 'Failed to fetch Popups info');
            }
        } catch (error) {
            console.log('Error fetching popups', error);
        }
    };

    const handleImageUpload = (popId, event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image')) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const updatedPops = pops.map(pop => {
                    if (pop.id === popId) {
                        return {...pop, imgPath: reader.result};
                    }
                    return pop;
                });
                setPops(updatedPops);
            };
            reader.readAsDataURL(file);
        } else {
            alert('Please upload an image file.');
        }
    };

    const handleClick = (popId) => {
        fileInputRefs.current[popId].current.click();
    };

    const handleSavePop = async (pop) => {
        try {
            const response = await fetch(`/api/websites/${websiteId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                },
                body: JSON.stringify({
                    image: pop.imgPath,
                    title: pop.title,
                    description: pop.description,
                    notificationTime: pop.time,
                    link: pop.link,
                    bodyColor: pop.bodyColor,
                    textColor: pop.textColor
                })
            });

            const data = await response.json();
            if (response.ok) {
                setMessage('Popup saved successfully!');
                console.log(data); // Or update state to show new popup in UI
                fetchPopups(websiteId);

            } else {
                throw new Error(data.message || 'Failed to save popup');
            }
        } catch (error) {
            setError(`Error saving popup: ${error.message}`);
        }
    };

    const addPop = () => {
        const newPopId = pops.length + 1;
        const newPop = {
            id: newPopId,
            imgPath: '',
            title: '',
            description: '',
            time: '',
            link: "",
            bodyColor: "#FFFFFF", // Default body color is white
            textColor: "#000000", // Default text color is black
            fileInputRef: React.createRef()
        };
        fileInputRefs.current[newPopId] = newPop.fileInputRef;  // Store ref in the refs object
        setPops(pops =>[...pops, newPop]);
    };

    const deletePop = (popId) => {
        setPops(pops.filter(pop => pop.id !== popId));
        delete fileInputRefs.current[popId];  // Remove the ref from the refs object

    };

    const deletePopup = async (popupId) => {
        try {
            const response = await fetch(`/api/websites/${websiteId}/popups/${popupId}`, {
                method: 'DELETE'
            });
            const data = await response.json();
            if (response.ok) {
                console.log('Popup deleted successfully:', data);
                fetchPopups(websiteId);                    
            } else {
                throw new Error(data.message || 'Failed to delete popup');
            }
        } catch (error) {
            console.error('Error deleting popup:', error);
        }
    };

    const handleInputChange = (popId, field, value) => {
        setPops(pops.map(pop => {
            if (pop.id === popId) {
                if (field === 'link') {
                    // Ensure the link starts with http:// or https://
                    if (value && !value.startsWith('http://') && !value.startsWith('https://')) {
                        value = 'http://' + value;
                    }
                }
                return {...pop, [field]: value};
            }
            return pop;
        }));
    };
    

    const testPopList = async () => {
        try {
            const response = await fetch(`/api/websites/${websiteId}/popups`);
            const data = await response.json();
            if (response.ok) {
                console.log("testPopList funciona até aqui");
                setPopList(data);
                // Remove existing divs
                const existingDivs = document.querySelectorAll('.bg-white.absolute.top-10.right-5.z-50.w-fit.h-fit');
                existingDivs.forEach(div => div.remove());
                // Create new divs
                const outerDiv = document.createElement('div');
                outerDiv.className = 'absolute top-10 right-5 w-full z-50 flex flex-col items-end';
            
                data.forEach((pop, index) => {
                    const div = document.createElement('div');
                    div.className = 'flex justify-around items-center rounded-lg shadow-lg p-4 sm:w-1/5 md:!w-1/5 lg:!w-1/5 mb-4 transition-opacity duration-500 ease-in-out slideInRight';
                    div.style.backgroundColor = pop.bodyColor; // Set background color
                    div.style.color = pop.textColor; // Set text color
                    div.style.animationDelay = `${index * 100}ms`; // Delay each notification for staggered effect
                    div.style.opacity = '0'; // Initially set opacity to 0
            
                    const img = document.createElement('img');
                    img.src = pop.image;
                    img.className = 'size-16 rounded-lg shadow-sm mx-4 my-2';
            
                    const divInner = document.createElement('div');
                    divInner.className = 'flex-grow';
            
                    const h2 = document.createElement('h2');
                    h2.textContent = pop.title;
                    h2.className = 'font-bold mb-1';
            
                    const p1 = document.createElement('p');
                    p1.textContent = pop.description;
                    p1.className = 'text-xs font-medium';
            
                    const p2 = document.createElement('p');
                    p2.textContent = pop.notificationTime;
                    p2.className = 'ml-3 text-xs';
            
                    div.appendChild(img);
                    divInner.appendChild(h2);
                    divInner.appendChild(p1);
                    div.appendChild(divInner);
                    div.appendChild(p2);
                    outerDiv.appendChild(div); // Append div to the outer div
                });
            
                document.body.appendChild(outerDiv);
                console.log("testPopList funciona até aqui2");
            
                // After a delay, change the opacity to 1 to initiate the fade-in effect
                setTimeout(() => {
                    data.forEach(() => {
                        outerDiv.childNodes.forEach(div => {
                            div.style.opacity = '1';
                            div.transform = 'translateX(0)';
                        });
                    });
                }, 1000); // Delay for 100ms to start the fade-in animation
            
                // After a delay, change opacity to 0 to initiate the fade-out effect
                setTimeout(() => {
                    data.forEach(() => {
                        outerDiv.childNodes.forEach(div => {
                            div.style.opacity = '0';
                        });
                    });
                
                    // After the fade-out animation finishes, remove the divs
                    setTimeout(() => {
                        outerDiv.innerHTML = '';
                    }, 500); // Wait for the fade-out transition duration
                }, 5000); // Wait for 5 seconds before starting the fade-out animation
            
                console.log("testPopList funciona até aqui3");
            } else {
                throw new Error(data.message || 'Failed to fetch Popups info');
            }                     
            
        } catch (error) {
            console.log('Error fetching popups', error);
        }
    };

    return (
        <div className='flex flex-col lg:flex-row justify-between w-full h-full p-4'>
            <div className='w-full lg:w-1/2 lg:ml-4'>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                {userData ? (
                    <>
                        {userData.user.planId === "Single Spark Plan" && userData.user.websites.length >= 1 ? (
                            <p>You are currently on the Single Spark Plan and already have a website registered. Please <a href="/payment?priceId=price_1P6iu5L2M4UlckgMemvRMpBV" className="text-blue-500 hover:text-blue-700">upgrade</a> to the TNT Explosion Plan to add more websites.</p>
                        ) : (
                            <div>
                                <p className='text-black mt-8'>Enter a domain to start using PopSpark:</p>
                                <form className='flex flex-col sm:flex-row w-full lg:w-1/2 py-3 mt-2 justify-around' onSubmit={handleAddDomain}>
                                    <input type="text" value={domain} onChange={e => setDomain(e.target.value)} placeholder="Enter domain" required className='mt-1 mr-5 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500' />
                                    <button className='btn block bg-blue-700 text-white border-none mt-4 sm:mt-0' type="submit">Add Domain</button>
                                </form>
                            </div>
                        )}
                        <div className='mt-10 my-4 w-full'>
                            {message && <p>{message}</p>}
                            <h2 className='text-black font-bold text-xl'>Your Domains:</h2>
                            <div className='flex flex-col'>
                                {userData.user.websites.map((web, index) => (
                                    <div key={index} className='flex w-2/3 justify-between items-center my-2'>
                                        <p onClick={() => handleSelectDomain(web)} className="cursor-pointer text-blue-500 hover:text-blue-700 bg-white rounded pl-3 pr-3 py-1 shadow-lg border-solid border border-gray-100 w-full lg:w-auto">
                                            🔗 {web}
                                        </p>
                                        <button className='ml-2 bg-red-600 text-white rounded-full p-2' onClick={() => deleteDomain(web)}>🗑️</button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                ) : <p>Loading user data...</p>}
            </div>
            <div className='flex flex-col w-full lg:w-1/2'>
                {selectedDomain && (
                    <div className='mt-8'>
                        <div className='flex justify-center items-center mb-4'>
                            <h1 className='text-black text-2xl font-semibold sm:text-md'>{selectedDomain}</h1>
                            <button onClick={addPop} className='bg-blue-700 text-white text-2xl rounded-full ml-4 p-2'>+</button>
                            <button onClick={() => pops.forEach(pop => handleSavePop(pop))} className='bg-blue-700 rounded text-white font-bold px-4 py-2 ml-4'>Update</button>
                            <button onClick={testPopList} className='bg-blue-700 rounded text-white font-bold px-4 py-2 ml-4'>Test</button>
                        </div>
                        {pops.map((pop, index) => (
                            <div key={index} className="flex flex-col lg:flex-row bg-white justify-around items-center p-4 rounded-2xl shadow-lg border-solid border mb-4">
                                <div className='flex items-center mb-4 lg:mb-0'>
                                    <p className='mr-2'>🤏</p>
                                    <div className='relative'>
                                        <button className='absolute inset-0 z-10 text-white font-bold' onClick={() => handleClick(pop.id)}>🖼️</button>
                                        {pop.imgPath && (
                                            <img src={pop.imgPath} className='w-16 h-16 object-cover rounded-full border-2 border-black' />
                                        )}
                                        <input type="file" accept="image/*" onChange={(e) => handleImageUpload(pop.id, e)} ref={pop.fileInputRef} className="hidden" />
                                    </div>
                                </div>
                                <div className='flex flex-col mx-2 mb-4 lg:mb-0'>
                                    <input value={pop.title} onChange={(e) => handleInputChange(pop.id, 'title', e.target.value)} className='bg-white text-black font-black border-black border-2 rounded mb-1 p-2' placeholder="TITLE HERE"/>
                                    <input value={pop.description} onChange={(e) => handleInputChange(pop.id, 'description', e.target.value)} className='bg-white text-black font-black border-black border-2 rounded mb-1 p-2' placeholder="TEXT HERE"/>
                                    <input value={pop.link} onChange={(e) => handleInputChange(pop.id, 'link', e.target.value)} className='bg-white text-black font-black border-black border-2 rounded mb-1 p-2' placeholder="LINK HERE"/>
                                    <p className='text-black'>Body Color:</p>
                                    <select value={pop.bodyColor} onChange={(e) => handleInputChange(pop.id, 'bodyColor', e.target.value)} className='bg-white text-black font-black border-black border-2 rounded mb-1 p-2'>
                                        <option value="#0345C5" style={{ backgroundColor: '#0345C5', color: 'white' }}>Blue</option>
                                        <option value="#353535" style={{ backgroundColor: '#353535', color: 'white' }}>Dark Gray</option>
                                        <option value="#4A892C" style={{ backgroundColor: '#4A892C', color: 'white' }}>Green</option>
                                        <option value="#D9D9D9" style={{ backgroundColor: '#D9D9D9', color: 'black' }}>Light Gray</option>
                                        <option value="#E33629" style={{ backgroundColor: '#E33629', color: 'white' }}>Red</option>
                                        <option value="#FF7A00" style={{ backgroundColor: '#FF7A00', color: 'black' }}>Orange</option>
                                        <option value="#FFE500" style={{ backgroundColor: '#FFE500', color: 'black' }}>Yellow</option>
                                        <option value="#FFFFFF" style={{ backgroundColor: '#FFFFFF', color: 'black' }}>White</option>
                                    </select>
                                    <p className='text-black'>Text Color:</p>
                                    <select value={pop.textColor} onChange={(e) => handleInputChange(pop.id, 'textColor', e.target.value)} className='bg-white text-black font-black border-black border-2 rounded mb-1 p-2'>
                                        <option value="#0345C5" className="color-option">
                                            <span className="color-square" style={{ backgroundColor: '#0345C5' }}></span>Blue
                                        </option>
                                        <option value="#353535" className="color-option">
                                            <span className="color-square" style={{ backgroundColor: '#353535' }}></span>Dark Gray
                                        </option>
                                        <option value="#4A892C" className="color-option">
                                            <span className="color-square" style={{ backgroundColor: '#4A892C' }}></span>Green
                                        </option>
                                        <option value="#D9D9D9" className="color-option">
                                            <span className="color-square" style={{ backgroundColor: '#D9D9D9' }}></span>Light Gray
                                        </option>
                                        <option value="#E33629" className="color-option">
                                            <span className="color-square" style={{ backgroundColor: '#E33629' }}></span>Red
                                        </option>
                                        <option value="#FF7A00" className="color-option">
                                            <span className="color-square" style={{ backgroundColor: '#FF7A00' }}></span>Orange
                                        </option>
                                        <option value="#FFE500" className="color-option">
                                            <span className="color-square" style={{ backgroundColor: '#FFE500' }}></span>Yellow
                                        </option>
                                        <option value="#FFFFFF" className="color-option">
                                            <span className="color-square" style={{ backgroundColor: '#FFFFFF' }}></span>White
                                        </option>
                                    </select>
                                </div>
                                <div className='flex flex-col items-center mb-4 lg:mb-0'>
                                    <input value={pop.time} onChange={(e) => handleInputChange(pop.id, 'time', e.target.value)} type='text' className='bg-white text-black font-medium text-xs text-center border-black border-2 rounded w-fit p-2 mb-2' placeholder='NOW'/>
                                    <button onClick={() => deletePop(pop.id)} className='bg-red-400 text-black rounded px-4 py-2'>Close</button>
                                </div>
                            </div>
                        ))}
                        {popList.map((pop) => (
                            <div key={pop.id} className='flex flex-col lg:flex-row items-center mb-4'>
                                <a href={pop.link ? pop.link : null} target="_blank" rel="noopener noreferrer" className="flex justify-around items-center rounded-lg shadow-lg p-4 sm:w-1/5 md:!w-2/3 lg:!w-2/3" style={{backgroundColor: pop.bodyColor, color: pop.textColor}}>
                                    
                                    <img src={pop.image} className='size-16 rounded-lg shadow-sm mx-4 my-2' alt="Popup Visual" />
                                    
                                    <div className='flex-grow'>
                                        <h2 className='font-bold mb-1'>{pop.title}</h2>
                                        <p className='text-xs font-medium'>{pop.description}</p>
                                    </div>
                                    
                                    <p className='ml-3 text-xs'>{pop.notificationTime}</p>
                                    
                                </a>
                                <button onClick={() => deletePopup(pop._id)} className='bg-red-500 text-white rounded-full p-2 ml-0 lg:ml-4'>🗑️</button>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}
export default AddWeb;
