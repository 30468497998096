import React from 'react';
import facebook from './icons/facebook.svg';
import twitter from './icons/twitter.svg';
import email from './icons/email.svg';

const Footer = () => {
  return (
    <footer className="bg-white text-gray-600 py-6 border-t">
      <div className="container mx-auto text-center">
        <p className="mb-4">&copy; {new Date().getFullYear()} PopSpark. All rights reserved.</p>
        <div className="flex justify-center space-x-8 mb-4">
          <a href="/terms-of-service" className="hover:text-gray-900 transition duration-300">Terms of Service</a>
          <a href="/privacy-policy" className="hover:text-gray-900 transition duration-300">Privacy Policy</a>
        </div>
        <div className="flex justify-center space-x-4">
          <a href="https://facebook.com" className="transition transform hover:scale-110">
            <img src={facebook} alt="Facebook" className="w-6 h-6" />
          </a>
          <a href="https://twitter.com" className="transition transform hover:scale-110">
            <img src={twitter} alt="Twitter" className="w-6 h-6" />
          </a>
          <a href="https://gmail.com" className="transition transform hover:scale-110">
            <img src={email} alt="Email" className="w-6 h-6" />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
