import React from 'react';
import { ArrowRightIcon } from '@heroicons/react/solid';

const TestPopups = () => {
  const goToDemoSection = () => {
    // Refresh the page and navigate to #demo
    window.location.href = window.location.origin + '/#demo';
    window.location.reload(); // Optional: Only if you need to fully reload the page


  };

  return (
    <div className="py-12">
      <div className="container mx-auto text-center">
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-md mx-auto">
          <h2 className="text-3xl font-bold mb-4">Test our Popups</h2>
          <p className="text-lg mb-6">Experience the power of customized popups on your website. Click below to see them in action!</p>
          <button
            onClick={goToDemoSection}
            className="mt-4 mx-auto px-6 py-3 bg-blue-600 text-white font-semibold rounded-full hover:bg-blue-700 transition-colors flex items-center justify-center space-x-2"
          >
            <span>Go to Demo</span>
            <ArrowRightIcon className="h-5 w-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TestPopups;
