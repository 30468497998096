import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-6">
      <div className="max-w-4xl w-full bg-white p-8 rounded-lg shadow-md">
        <h1 className="text-3xl font-bold text-gray-900 mb-6 text-center">Privacy Policy for PopSpark.com</h1>
        <p className="text-gray-700 mb-4"><strong>Effective Date:</strong> [Insert Date]</p>

        <h2 className="text-2xl font-semibold text-gray-900 mb-4">1. Information Collection</h2>
        <p className="text-gray-700 mb-4">We collect information directly from users when they use our application. The types of information we may collect include:</p>
        <ul className="list-disc list-inside text-gray-700 mb-6">
          <li><strong>Personal Identifiers:</strong> such as names and email addresses when users create an account or contact us.</li>
          <li><strong>User Content:</strong> such as inputs, queries, or data entered while using the application.</li>
          <li><strong>Usage Data:</strong> information on how users interact with the app, which may include dates and times of access, app features or pages viewed, app performance data, and other system activity.</li>
          <li><strong>Device Information:</strong> such as hardware model, operating system version, unique device identifiers, and mobile network information.</li>
        </ul>

        <h2 className="text-2xl font-semibold text-gray-900 mb-4">2. Use of Information</h2>
        <p className="text-gray-700 mb-4">We use the information collected for the following purposes:</p>
        <ul className="list-disc list-inside text-gray-700 mb-6">
          <li>To provide, maintain, and improve the app;</li>
          <li>To respond to user requests and provide customer service;</li>
          <li>To process transactions and send related information, including confirmations and invoices;</li>
          <li>To send technical notices, updates, security alerts, and support and administrative messages;</li>
          <li>To detect, prevent, and address technical issues or potential security breaches.</li>
        </ul>

        <h2 className="text-2xl font-semibold text-gray-900 mb-4">3. Sharing of Information</h2>
        <p className="text-gray-700 mb-4">We may share information as follows:</p>
        <ul className="list-disc list-inside text-gray-700 mb-6">
          <li>With service providers who perform services on our behalf, such as data storage and analytics.</li>
          <li>If we believe disclosure is necessary to comply with any applicable law, regulation, legal process, or governmental request.</li>
          <li>To enforce our rights, prevent fraud, and for safety to protect our users or the public as required or permitted by law.</li>
        </ul>

        <h2 className="text-2xl font-semibold text-gray-900 mb-4">4. Data Security</h2>
        <p className="text-gray-700 mb-6">We take reasonable measures to help protect personal information from loss, theft, misuse, and unauthorized access, disclosure, alteration, and destruction.</p>

        <h2 className="text-2xl font-semibold text-gray-900 mb-4">5. International Transfers of Personal Information</h2>
        <p className="text-gray-700 mb-6">Your personal information may be stored and processed in any country where we have facilities or in which we engage service providers. By using the app, you consent to the transfer of information to countries outside of your country of residence, which may have different data protection rules than your country.</p>

        <h2 className="text-2xl font-semibold text-gray-900 mb-4">6. Your Data Protection Rights</h2>
        <p className="text-gray-700 mb-6">Depending on your location, you may have rights under data protection laws, which could include the right to access, correct, or delete your personal information we have collected.</p>

        <h2 className="text-2xl font-semibold text-gray-900 mb-4">7. Changes to This Privacy Policy</h2>
        <p className="text-gray-700 mb-6">We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy and, in some cases, we may provide additional notice (such as adding a statement to our homepage or sending you a notification).</p>

        <h2 className="text-2xl font-semibold text-gray-900 mb-4">8. Contact Us</h2>
        <p className="text-gray-700">If you have any questions about this Privacy Policy, please contact us at <a href="mailto:raposobernardo@gmail.com" className="text-blue-600 hover:underline">raposobernardo@gmail.com</a>.</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
