import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { jwtDecode } from 'jwt-decode';
import { useLocation, useNavigate } from 'react-router-dom';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4"
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a"
        }
    }
};



const CheckoutForm = () => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const priceId = queryParams.get('priceId');
    const planId = queryParams.get('planId'); // Assuming planId is passed as a query parameter
    const [productDetails, setProductDetails] = useState(null);

    useEffect(() => {
        if (priceId) {
            const fetchProductDetails = async () => {
                try {
                    const response = await fetch(`/api/stripe/product/${priceId}`);
                    const data = await response.json();
                    if (response.ok) {
                        setProductDetails(data);
                    } else {
                        throw new Error(data.message);
                    }
                } catch (error) {
                    console.error('Failed to fetch product details:', error.message);
                    setError(error.message);
                }
                
            };
            fetchProductDetails();
        }
    }, [priceId]);

    const getUserIdFromToken = () => {
        const token = localStorage.getItem('jwtToken');
        if (token) {
            try {
                const decoded = jwtDecode(token);
                return decoded.userId;
            } catch (error) {
                console.error('Error decoding token:', error);
                return null;
            }
        }
        return null;
    };

    const updatePlan = async (userId, planId) => {
        const response = await fetch('/api/user/update-plan', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userId, planId })
        });
        const data = await response.json();
        if (data.success) {
            console.log("Plan updated successfully!");
            navigate('/dashboard'); // Navigate to dashboard
        } else {
            console.error("Failed to update plan:", data.message);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }

        setLoading(true);
        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            setError(error.message);
            setLoading(false);
            return;
        }

        const userId = getUserIdFromToken();

        const response = await fetch('/api/payment', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ paymentMethodId: paymentMethod.id, amount: productDetails.price.unit_amount, userId, planId }) // Include planId to update the user's plan
        });
        const paymentResult = await response.json();

        if (paymentResult.success) {
            alert('Payment successful!');
            await updatePlan(userId, planId);
            navigate('/dashboard'); // Navigate to dashboard
        } else {
            setError(paymentResult.message);
        }
        setLoading(false);
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            {productDetails && (
                <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-md">
                    <div className="mb-6 text-center">
                        <h3 className="text-3xl font-bold text-gray-900">{productDetails.product.name}</h3>
                        <p className="my-4 text-gray-700">{productDetails.product.description}</p>
                        <p className="text-2xl font-bold text-gray-900">${productDetails.price.unit_amount / 100} USD</p>
                    </div>
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                            <label className="block text-gray-700 text-sm font-bold mb-2">Card Details</label>
                            <CardElement options={CARD_ELEMENT_OPTIONS} className="p-3 bg-gray-100 rounded" />
                            
                        </div>
                        {error && <div className="text-red-500 text-sm">{error}</div>}
                        <button type="submit" disabled={!stripe || loading} className="w-full bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 transition duration-300">
                            {loading ? "Processing..." : "Pay"}
                        </button>
                    </form>
                </div>
            )}
        </div>
    );
};

const Payment = () => {
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm />
        </Elements>
    );
};

export default Payment;
