import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FcGoogle } from "react-icons/fc";
import logo from "./icons/logo.svg";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { app } from '../firebase';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const provider = new GoogleAuthProvider();
    const auth = getAuth(app);

    const checkUserDB = async (email) => {
        const response = await fetch(`/api/user/signin/${email}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to check user in DB');
        }
        return response;
    };

    const signWithGoogle = () => {
        signInWithPopup(auth, provider)
        .then((result) => {
            const user = result.user;
            console.log("This is the user:", user.email);
            checkUserDB(user.email).then(handleResponse);
        }).catch((error) => {
            alert(error.message);
        });
    };

    const handleLogin = async (email, password) => {
        const response = await fetch('/api/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email, password })
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to login');
        }
        return response;
    };

    const handleResponse = async (response) => {
        const data = await response.json();
        localStorage.setItem('jwtToken', data.token);
        console.log("This is the token:", data.token);
        alert('Login successful!');
        navigate('/dashboard');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await handleLogin(email, password);
            await handleResponse(response);
        } catch (error) {
            console.error('Login error:', error);
            alert(error.message);
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <form onSubmit={handleSubmit} className="flex flex-col p-8 bg-white rounded-lg shadow-lg w-full max-w-sm">
                <img src={logo} alt="Logo" className='h-10 self-center mb-7'/>
                <div className="mb-4 flex flex-col">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Email:
                    </label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required
                        autoComplete="email"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 bg-slate-400"
                    />
                </div>
                <div className="mb-6 flex flex-col">
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                        Password:
                    </label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        required
                        autoComplete="current-password"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 bg-slate-400"
                    />
                </div>
                <button type="submit" className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold mb-5 py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                    Log In
                </button>

                <button type="button" onClick={signWithGoogle} className="flex items-center justify-center w-full bg-white border-solid border-gray-500 border-2 hover:bg-gray-500 hover:text-white text-black font-bold mb-5 py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                    <FcGoogle className="mr-2" /> Sign in with Google
                </button>

                <p className='mt-5 text-sm text-gray-700 text-center'>Don't have an account? <a className='underline text-blue-600 hover:text-blue-700' href='/signup'>Click here!</a></p>
            </form>
        </div>
    );
}

export default Login;
