import React from 'react';

const Billing = () => {
    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="max-w-2xl w-full bg-white p-8 rounded-lg shadow-md">
                <h1 className="text-3xl font-bold text-gray-900 text-center mb-6">Billing Information</h1>
                <div className="text-center">
                    <p className="text-gray-700">Billing details will be displayed here.</p>
                    {/* Further billing details and components can be added here */}
                </div>
            </div>
        </div>
    );
};

export default Billing;
