import React from 'react';
import { useLocation } from 'react-router-dom';
import logo from './icons/logo.svg';

const PaymentComplete = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const paymentStatus = searchParams.get('payment_status');
    const paymentId = searchParams.get('payment_intent');

    const getMessage = () => {
        switch (paymentStatus) {
            case 'success':
                return `Payment successful! Your payment ID is: ${paymentId}`;
            case 'failed':
                return 'Payment failed. Please try again or contact support.';
            default:
                return 'Payment status unknown. Please check your account or contact support.';
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-md text-center">
                <img src={logo} alt="Logo" className="h-10 mx-auto mb-6" />
                <h1 className="text-3xl font-bold text-gray-900 mb-4">Payment Status</h1>
                <p className="text-lg text-gray-700 mb-6">{getMessage()}</p>
                <a href="/dashboard" className="bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 transition duration-300">Go to Dashboard</a>
            </div>
        </div>
    );
};

export default PaymentComplete;
