import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Signup from './components/Signup';
import Login from './components/Login';
import Home from './components/Home'; // Assuming you have a basic Home component
import Footer from './components/Footer';
import Dashboard from './components/Dashboard';
import Payment from './components/Payment';
import PaymentComplete from './components/PaymentComplete';
import ProtectedRoute from './components/ProtectedRoute';
import Billing from './components/Billing';
import PrivacyPolicy from './components/PrivacyPolicy';
import PageNotFound from './components/NotFoundPage';
import { GoogleOAuthProvider } from '@react-oauth/google';
import "./index.css";



function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
          <Route path="/billing" element={<Billing />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/payment-complete" element={<PaymentComplete />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      <Footer/>
      </div>
    </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
