import React, { useState } from 'react';

const CodeGen = ({ selectedDomain }) => {
    const [copySuccess, setCopySuccess] = useState('');
    const codeSnippet = `<script defer data-domain="${selectedDomain}" src="https://usepopspark.com/js/script"></script>`;

    const handleCopyCode = async () => {
        try {
            await navigator.clipboard.writeText(codeSnippet);
            setCopySuccess('Code copied successfully!');
            console.log(selectedDomain);
        } catch (err) {
            setCopySuccess('Failed to copy!');
        }
    };

    return (
        <div className="max-w-xl w-full sm:w-2/3 md:w-1/2 lg:w-1/3 bg-white p-4 sm:p-6 lg:p-8 rounded-lg shadow-md text-center mx-auto">
            <h1 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-4 sm:mb-6">JavaScript Code Snippet</h1>
            <button
                onClick={handleCopyCode}
                className="bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
            >
                Get your JavaScript code
            </button>
            {copySuccess && <p className="mt-4 text-green-500">{copySuccess}</p>}
        </div>
    );
};

export default CodeGen;
