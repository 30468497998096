// auth.js
import {jwtDecode} from 'jwt-decode';

export const isAuthenticated = () => {
    const token = localStorage.getItem('jwtToken');
    if (!token) {
        return false;
    }

    try {
        const { exp } = jwtDecode(token);
        if (exp < (new Date().getTime() + 1) / 1000) {
            return false; // check if the token is expired
        }
    } catch (error) {
        return false;
    }

    return true;
};
