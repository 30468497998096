import React, { useState, useEffect } from 'react';
import { CheckIcon, GlobeAltIcon, LightningBoltIcon, ChartBarIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';

// Stripe plans
const plans = [
  {
    name: 'Basic Plan',
    link:
      //process.env.NODE_ENV === "development"
     /* ? */"https://buy.stripe.com/fZe5nr78OeI15RCbIK" // payment link for basic plan
     /* : ""*/,
    priceId:
     // process.env.NODE_ENV === "development"
    /*  ? */"price_1PYuk2L2M4UlckgMwYTv89Mp"
     /* : ""*/,
    price: 49,
    duration: "lifetime",
    features: [
      { icon: CheckIcon, text: "One website" },
      { icon: CheckIcon, text: "Unlimited customizable popups" },
      { icon: ChartBarIcon, text: "Basic analytics" }
    ],
    color: "bg-blue-600",
    emoji: "🎯",
    textColor: "text-blue-600",
    strikeThroughPrice: "$99"
  },
  {
    name: 'Premium Plan',
    link:
     // process.env.NODE_ENV === "development"
      /*?*/ "https://buy.stripe.com/test_14k7uj2Nj3N08sE4gi" // payment link for premium plan
      /*: ""*/,
    
    priceId:
     // process.env.NODE_ENV === "development"
     /* ? */"price_1PYuoqL2M4UlckgMeOM1tqbo"
     /* : ""*/,
    price: 99,
    duration: "lifetime",
    features: [
      { icon: GlobeAltIcon, text: "Unlimited websites" },
      { icon: CheckIcon, text: "Unlimited customizable popups" },
      { icon: LightningBoltIcon, text: "Advanced analytics" }
    ],
    color: "bg-orange-600",
    emoji: "🚀",
    textColor: "text-orange-600",
    strikeThroughPrice: "$199"
  }
];

const PricingPlans = () => {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState(null);

  const fetchUserEmail = () => {
    const token = localStorage.getItem('jwtToken');
    if (token === null) {
      console.log("No token found!");
    } else {
      const decodedToken = jwtDecode(token);
      const userId = decodedToken.userId;

      fetch(`/api/user/${userId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        }
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }
        return response.json();
      })
      .then(data => {
        if (!data.user.email) {
          navigate('/login');
        } else {
          setUserEmail(data.user.email);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error.message);
      });
    }
  };

  useEffect(() => {
    fetchUserEmail();
  }, []);

  return (
    <div className="py-12 my-20">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-6">Choose Your Plan</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {plans.map((plan, index) => (
            <div key={index} className={`bg-white p-8 rounded-lg shadow-lg flex flex-col justify-between border border-gray-300 transition-shadow`}>
              <div>
                <h3 className={`text-4xl font-black mb-4 ${plan.textColor}`}>{plan.name} <span role="img" aria-label="target">{plan.emoji}</span></h3>
                <p className="text-2xl font-light mb-2">
                  <span className="line-through text-gray-500">{plan.strikeThroughPrice}</span> <span className={`${plan.textColor} text-4xl font-bold`}>${plan.price}</span>
                </p>
                <ul className="text-left mb-6">
                  {plan.features.map((feature, i) => (
                    <li key={i} className="mb-2 flex items-center">
                      <feature.icon className="h-6 w-6 text-green-500 mr-2" /> {feature.text}
                    </li>
                  ))}
                </ul>
              </div>
              <a 
                target='_blank' 
                href={userEmail ? `${plan.link}?prefilled_email=${userEmail}` : '#'} 
                className={`px-6 py-3 ${plan.color} text-white rounded-full mt-6 transition-colors`}
              >
                Choose {plan.name.split(' ')[0]}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PricingPlans;
