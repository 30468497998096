import React, { useState } from 'react';
import logo from './icons/logo.svg';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-white shadow-md">
      <div className="container mx-auto p-4 flex items-center justify-between">
        <div className="flex items-center">
          <a href="/">
            <img src={logo} className="h-10" alt="Logo" />
          </a>
        </div>
        <div className="hidden lg:flex items-center space-x-8">
          <a href="#pricing" className="text-gray-900 font-medium text-lg hover:text-blue-600">Pricing</a>
          <a href="#FAQ" className="text-gray-900 font-medium text-lg hover:text-blue-600">FAQ</a>
        </div>
        <div className="hidden lg:block">
          <a className="mt-6 px-6 py-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition duration-300" href="/login">Get started</a>
        </div>
        <div className="lg:hidden">
          <button onClick={toggleMenu} className="text-gray-900 focus:outline-none">
            {isOpen ? <AiOutlineClose className="h-8 w-8" /> : <AiOutlineMenu className="h-8 w-8" />}
          </button>
        </div>  
      </div>
      {isOpen && (
        <div className="lg:hidden">
          <div className="container mx-auto p-4 flex flex-col space-y-4">
            <a href="#pricing" className="text-gray-900 font-medium text-lg hover:text-blue-600">Pricing</a>
            <a href="#FAQ" className="text-gray-900 font-medium text-lg hover:text-blue-600">FAQ</a>
            <a className="bg-blue-600 text-white font-bold px-5 py-2 rounded hover:bg-blue-700 transition duration-300" href="/login">Login</a>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
