// ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { isAuthenticated } from './auth';

const ProtectedRoute = ({ element: Element, ...rest }) => {
    return isAuthenticated() ? Element : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
