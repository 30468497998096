import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "./icons/logo.svg";
import AddWeb from "./AddWeb";
import CodeGen from "./CodeGen";
import {jwtDecode} from 'jwt-decode';
import { UserContext } from './UserContext'; // Import the UserContext

const getUserIdFromToken = () => {
  const token = localStorage.getItem('jwtToken');
  if (token) {
      try {
          const decoded = jwtDecode(token);
          console.log('Decoded token:', decoded);
          return decoded.userId;
      } catch (error) {
          console.error('Error decoding token:', error);
          return null;
      }
  }
  return null;
};

const isTrialPeriodOver = (trialStartDate, trialDurationInDays = 5) => {
  if (!trialStartDate) {
    console.log('No trialStartDate provided');
    return true; // If no trialStartDate is provided, consider the trial as over
  }
  
  const currentDate = new Date();
  const trialEndDate = new Date(trialStartDate);
  trialEndDate.setDate(trialEndDate.getDate() + trialDurationInDays);
  const isOver = currentDate > trialEndDate;
  console.log('Checking trial period:', {
    trialStartDate,
    trialEndDate,
    currentDate,
    isOver
  });
  return isOver;
};

function Dashboard() {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext); // Use the UserContext
  const [userData, setUserData] = useState(null);
  const [domain, setDomain] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const userId = getUserIdFromToken();
    if (!userId) {
      console.log('No user ID found, redirecting to login.');
      navigate('/login'); // Redirect to login if userId is not found
      return;
    }

    // Fetch user data
    const fetchUserData = async () => {
        try {
            const response = await fetch(`/api/user/${userId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                console.log('Fetched user data:', data);
                console.log('trialStartDate:', data.user.trialStartDate); // Log the trialStartDate
                setUserData(data);
                setUser(data); // Update user context

                if (isTrialPeriodOver(data.user.trialStartDate) && !data.user.planId) {
                    console.log('Trial period is over and no plan is purchased, redirecting to pricing.');
                    navigate('/#pricing'); // Redirect to pricing if trial is over and no plan is purchased
                }
            } else {
                console.error('Failed to fetch user data, status:', response.status);
                navigate('/login'); // Redirect to login if fetching user data fails
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            navigate('/login'); // Redirect to login if there's an error
        }
    };

    fetchUserData();
  }, [navigate, setUser]);

  const handleLogout = () => {
    console.log('Logging out...');
    localStorage.removeItem('jwtToken'); // Remove the token
    navigate('/login');
  };

  if (!userData || !userData.user || (isTrialPeriodOver(userData.user.trialStartDate) && !userData.user.planId)) {
    console.log('Loading user data or user not authorized');
    return <div>Loading...</div>;
  }

  const planName = !isTrialPeriodOver(userData.user.trialStartDate) && !userData.user.planId ? 'Trial Period' : (userData.user.planId || 'No Plan');
  console.log('Plan name:', planName);

  return (
    <div className="min-h-screen bg-gray-100">
      <nav className="bg-white shadow-md">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-3 flex items-center justify-between">
          <img src={logo} className="h-10" alt="Logo"/>
          <div className="flex items-center space-x-4 sm:space-x-6">
            {userData && userData.user && !userData.user.planId && (
              <button onClick={() => navigate('/#pricing')} className="bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 transition duration-300">
                Get started
              </button>
            )}
            <div className="relative">
              <button onClick={() => setShowDropdown(!showDropdown)} className="bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 transition duration-300">
                Account
              </button>
              {showDropdown && (
                <div className="absolute right-0 mt-2 w-48 bg-white border rounded-lg shadow-lg py-1">
                  <a href="/billing" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">💵 Billing</a>
                  <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100">👋 Logout</button>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
      <main className="py-10">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-10">
            <h1 className="text-2xl sm:text-3xl font-bold text-gray-900">Welcome to Your Dashboard</h1>
            <h2 className="text-lg sm:text-xl font-semibold text-gray-700 mt-2">Plan: {planName}</h2>
          </div>
          <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
            <div className="flex flex-col space-y-6">
              <AddWeb callback={setDomain}/>
              <CodeGen selectedDomain={domain}/>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Dashboard;
