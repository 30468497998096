import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "./icons/logo.svg";

function Signup() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSignup = async (email, password) => {
        console.log("Sending to /signup:", JSON.stringify({ email, password }));
        const response = await fetch('/api/signup', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password })
        });
        return response;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await handleSignup(email, password);
            if (!response.ok){
                throw new Error("Network response was not ok");
            }
            const data = await response.json();
            alert(data.message);
            navigate('/login');
        } catch (error) {
            console.log("Signup error: ", error);
            alert(error.message);
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <form onSubmit={handleSubmit} className="flex flex-col p-8 bg-white rounded-lg shadow-lg">
                <img src={logo} alt="Logo" className='h-10 self-center mb-7'/>
                <div className="mb-6">
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-700">
                        Email:
                    </label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required
                        autoComplete="email"
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 bg-slate-400"
                    />
                </div>
                <div className="mb-6">
                    <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-700">
                        Password:
                    </label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        required
                        autoComplete="current-password"
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 bg-slate-400"
                    />
                </div>
                <button type="submit" className="w-full px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300">
                    Sign Up
                </button>
                <p className='mt-5 text-sm text-gray-700'>Already have an account? <a className='underline text-blue-600 hover:text-blue-700' href='/login'>Login here!</a></p>
            </form>
        </div>
    );
}

export default Signup;
