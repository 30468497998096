import React from 'react';
import { AiOutlineInfoCircle } from "react-icons/ai";


const FAQs = () => {

  return (
    <div class="flex flex-col items-center gap-10 p-6">
    <h1 class="flex text-3xl font-bold text-black"><AiOutlineInfoCircle className='mx-2' />Frequently Asked Questions</h1>

    <div class="grid grid-cols-1 sm:grid-cols-2 gap-8 text-black">
        <div class="flex flex-col gap-2">
            <h2 class="font-medium text-lg italic text-black">
                Is this a subscription?
            </h2>

            <span>
                Popspark is not a subscription service. It is a one-time purchase.
            </span>
        </div>

        <div class="flex flex-col gap-2">
            <h2 class="font-medium text-lg italic text-black">
                I acquired the simple plan. Can I upgrade to the pro plan?
            </h2>

            <span>
                Yes, you can upgrade from the simple plan to the pro plan at any time. Either click the "upgrade" button in your account, buy the pro plan, and your simple plan will be automatically canceled.
            </span>
        </div>

        <div class="flex flex-col">
            <h2 class="font-medium text-lg italic text-black">
                Upgrade License Type
            </h2>

            <span>
                There may be times when you need to upgrade your license from the original type you
                purchased and we have a solution that ensures you can apply your original purchase
                cost to the new license purchase.
            </span>
        </div>
        <div class="flex flex-col text-black">
            <h2 class="font-medium text-lg italic text-black">
                How do I use Popspark?
            </h2>

            <span>
                All you have to do is sign up, choose a plan, and start creating your own designs.
                You can use the designs for personal or commercial use. After you have created your design, you can copy the JavaScript code and paste it into your website.
                Most websites allow you to input JS code in the header or footer of your website.
            </span>
        </div>
    </div>
</div>
  );
}

export default FAQs;
